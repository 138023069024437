/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Space, Row, Col, Card, InputNumber,
  Popconfirm, Skeleton, Divider
} from 'antd'
import BudgetMarkDoneModal from '../../components/main/markDoneModal'

import { withTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined, /*EditOutlined, */CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
// import ReactGA from 'react-ga4'

import FloatLabel from '../common/floatLabel'

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const formItemsDivider = {
  orientationMargin: '20px',
  style: { margin: '4px 0 12px 0' }
}

class BudgetForm extends React.Component {
  state = {
    sum: 0,
    doneSum: 0,
    maxValue: 0,
    loaded: false,
    loading: false,
    timer: null,
    done: [],
    budgetItemIndex: undefined,
    counter: 0,
    data: undefined
  }

  formRef = React.createRef()

  handleBudgetItemFocus = (name) => {
    this.setState({ budgetItemFocus: name })
  }

  handleSubmit = (x) => {
    this.setState({ loading: true })

    this.props.formAction(this.formRef.current?.getFieldsValue()).then((json) => {
      this.setState({ loading: false })
    }).catch(error => {
      this.setState({ loading: false })
    })
  }

  calcBudget = () => {
    const budgetItems = this.formRef.current?.getFieldValue('budget_items')

    // budgetItems && budgetItems.length && budgetItems.forEach((item, i) => {
    //   if (item.parent_id !== undefined) {
    //     const parent = budgetItems.find(x => x.id === item.parent_id)
    //
    //     item.abort = false//parent.price > 0 && parent.left_price >= 0
    //   }
    // })

    const sum = budgetItems ? budgetItems.reduce((accumulator, object) => {
      if (object.parent_id === undefined) {
        if (object.subitems?.length) {
          if (object.price > 0 && object.left_price > 0) {
            return (accumulator ? accumulator : 0) + (object && object.left_price ? object.left_price : 0)
          } else {
            return (accumulator ? accumulator : 0)
          }
        } else {
          return (accumulator ? accumulator : 0) + (object && object.price && object.price > 0 ? object.price : 0)
        }
      } else {
        return (accumulator ? accumulator : 0) + (object && object.price ? object.price : 0)
        // return (accumulator ? accumulator : 0) + (object && object.price && !object.abort ? object.price : 0)
      }
    }, 0) : 0;

    this.setState({ sum })
  }

  calcDoneBudget = () => {
    const doneSum = this.state.done.reduce((accumulator, object) => {
      return (accumulator ? accumulator : 0) + (object && object.price ? object.price : 0);
    }, 0);

    this.setState({ doneSum })
  }

  setMarkDoneModalData = (index) => {
    const data = this.formRef.current?.getFieldValue('budget_items')[index]

    if (data?.subitems?.length) {
      this.markGroupAsDone(index, null)
    } else {
      this.setState({
        data, budgetItemIndex: index
      })
    }
  }

  markGroupAsDone = (index, value) => {
    const items = this.formRef.current?.getFieldValue('budget_items')
    const item = items[index]
    let toRemove = []

    // find any related item and remember indexes
    for (let idx in items) {
      if (items[idx].parent_id === item.id) {
        toRemove.push(idx)
      }
    }

    toRemove = toRemove.sort().reverse()

    // remove related items
    for (let tr in toRemove) {
      items.splice(toRemove[tr], 1)
    }

    // remove parent
    items.splice(index, 1)

    this.calcBudget()
    this.calcDoneBudget()
    this.saveFormData()
  }

  getNextCounter = () => {
    const counter = this.state.counter + 1
    this.setState({ counter })
    return counter
  }

  postProcessMarkAsDone = (budgetItemIndex, data) => {
    if (this.state.data.parent_id === undefined) {
      this.markAsFullDone(budgetItemIndex, data)
    } else {
      this.markAsPartialDone(budgetItemIndex, data)
    }
  }

  markAsFullDone = (index, value) => {
    const items = this.formRef.current?.getFieldValue('budget_items')
    const item = items[index]

    item.planned_price = item.price
    item.price = value
    items.splice(index, 1)

    const done = this.state.done
    done.push(item)

    this.setState({ done })
    this.calcBudget()
    this.calcDoneBudget()
    this.saveFormData()
  }

  markAsPartialDone = (index, price) => {
    const items = this.formRef.current?.getFieldValue('budget_items')
    const item = items[index]
    const done = this.state.done
    const subItem = {}

    subItem.name = item.name
    subItem.planned_price = item.price
    subItem.price = price

    let doneParentItem = done.find(x => x.id === item.parent_id)
    const parentItem = this.formRef.current?.getFieldValue('budget_items').find(x => x.id === item.parent_id)

    if (doneParentItem) {
      doneParentItem.subitems.push(subItem)
      doneParentItem.price = doneParentItem.subitems.reduce((acc, itm) => { return acc + itm.price }, 0)
      doneParentItem.planned_price = doneParentItem.planned_price + item.price
    } else {
      doneParentItem = { id: parentItem.id, planned_price: item.price, price: price, name: parentItem.name, subitems: [subItem] }
      done.push(doneParentItem)
    }

    items.splice(index, 1)

    const parent = items.find(x => x.id === item.parent_id)

    if (parent.price === undefined || parent.price === 0) {
      const withThisParent = items.filter(x => x.parent_id === item.parent_id)

      if (withThisParent.length === 0) {
        const parentIndex = items.findIndex(object => {
          return object.id === item.parent_id
        })

        items.splice(parentIndex, 1)
      }
    }

    this.formRef.current?.setFieldsValue({ budget_items: items })
    this.setState({ done })
    this.calcBudget()
    this.calcDoneBudget()
    this.saveFormData()
  }

  handleFormChange = (values) => {
    const done = this.state.done

    if (values.budget_items !== undefined) {
      values.budget_items.forEach((item, i) => {
        const elem = this.formRef.current.getFieldValue('budget_items')[i]

        const doneItem = done.find(x => x.id === elem.id)
        if (doneItem) {
          doneItem.name = elem.name
          this.setState({ done })
        }

        const items = this.formRef.current.getFieldValue('budget_items')

        if (elem.parent_id !== undefined) {
          const parent = items.find(x => x.id === elem.parent_id)

          if (parent.price !== '' && parent.price > 0) {
            this.calcParentChilds(parent.id)
          }
        } else if (elem.subitems !== undefined) {
          elem.planned_price = elem.price
          this.formRef.current.getFieldValue({ budget_items: items })
          this.calcParentChilds(elem.id)
        }
      })
    }

    this.calcBudget()
    this.saveFormData()
  }

  calcParentChilds = (parentId) => {
    const items = this.formRef.current.getFieldValue('budget_items')
    const parent = items.find(x => x.id === parentId)
    const parentItems = this.formRef.current.getFieldValue('budget_items').filter(x => x.parent_id === parentId)

    let sum = parentItems && parentItems.length ? parentItems.reduce((accumulator, object) => {
      return (accumulator ? accumulator : 0) + (object && object.price ? object.price : 0);
    }, 0) : 0;

    const parentDoneItem = this.state.done.find(x => x.id === parent.id)

    if (parentDoneItem) {
      const parentDoneItems = parentDoneItem.subitems
      const sum2 = parentDoneItems && parentDoneItems.length ? parentDoneItems.reduce((accumulator, object) => {
        return (accumulator ? accumulator : 0) + (object && object.price ? object.price : 0);
      }, 0) : 0;

      sum = sum + sum2
    }

    parent.left_price = parent.planned_price - sum
    this.formRef.current.getFieldValue({ budget_items: items })
  }

  saveFormData = () => {
    if (this.state.timer) return

    const _this = this
    const timer = setTimeout(() => {
      _this.props.formAction({...this.formRef.current?.getFieldsValue(), done: this.state.done})
      _this.setState({ timer: null })
    }, 2000)

    this.setState({ timer })
  }

  componentDidUpdate (prevProps, prevState) {
    const { formData } = this.props

    if(this.props.budgetMaxValue !== prevProps.budgetMaxValue)  {
      this.setState({
        maxValue: this.props.budgetMaxValue
      })
    }

    if (prevProps.formData !== formData) {
      const counterItems = (formData.data?.budget_items?.length && Math.max(...formData.data.budget_items.map(o => o.id))) || 0
      const counterDone = (formData.data?.done?.length && Math.max(...formData.data.done.map(o => o.id))) || 0
      const counter = Math.max(counterItems, counterDone)

      this.setState({
        loaded: true,
        maxValue: formData.max_value,
        done: formData.data.done || [],
        counter: counter
      }, () => {
        this.formRef.current.setFieldsValue(formData.data)
        this.calcBudget()
        this.calcDoneBudget()
      })
    }
  }

  removeItem = (remove, name) => {
    const item = this.formRef.current?.getFieldValue('budget_items')[name]
    remove(name)

    if (item.parent_id === undefined) {
      const items = this.formRef.current?.getFieldValue('budget_items').filter(x => x.parent_id !== item.id || x.parent_id === undefined)
      this.formRef.current?.setFieldsValue({ budget_items: items })
      const doneItems = this.state.done.filter(x => x.id !== item.id)

      this.setState({ done: doneItems }, () => {
        this.calcBudget()
        this.calcDoneBudget()
        this.saveFormData()
      })
    } else {
      const items = this.formRef.current?.getFieldValue('budget_items')
      const parentIndex = this.formRef.current?.getFieldValue('budget_items').findIndex(x => x.id === item.parent_id)
      items[parentIndex].subitems = items[parentIndex].subitems.filter(x => x !== item.id)
      this.formRef.current?.setFieldsValue({ budget_items: items })

      // remove done item from subitems
      // const done = this.state.done
      // const doneParentIndex = this.state.done.findIndex(x => x.id === item.parent_id)
      // done[doneParentIndex].subitems = done[doneParentIndex].subitems.filter(x => x.id !== item.id)
      //
      // // subitems.filter(x => x.id !== item.id)
      //
      // this.setState({ done })

      this.calcBudget()
      this.saveFormData()
    }
  }

  addSubitem = (add, name, rest) => {
    const parent_id = this.formRef.current?.getFieldValue('budget_items')[name].id
    const subitem = { parent_id, id: this.getNextCounter() }

    add(subitem, name + 1)

    const item = this.formRef.current?.getFieldValue('budget_items')[name]
    if (item.subitems === undefined) {
      item.subitems = []
    }

    if (item.price > 0 && (item.left_price === undefined || isNaN(item.left_price))) {
      item.planned_price = item.price
      item.left_price = item.price
    }

    item.subitems.push(subitem.id)

    this.calcBudget()
    this.saveFormData()
  }

  render () {
    const { isMobile } = this.props
    const { loaded } = this.state

    const popconfirmProps = {
      okText: 'Tak',
      cancelText: 'Anuluj',
      placement: 'bottom',
      cancelButtonProps: isMobile ? { size: 'large' } : undefined,
      okButtonProps: isMobile ? { size: 'large' } : undefined
    }

    const popconfirmItemDeleteProps = {
      ...popconfirmProps,
      placement: 'left'
    }

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <BudgetMarkDoneModal
          data={this.state.data}
          onOkAction={(data) => this.postProcessMarkAsDone(this.state.budgetItemIndex, data)}
          setData={this.setMarkDoneModalData}
        />
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='horizontal'
          className={'calculator' + (isMobile ? ' calculator-mobile' : '')}
          ref={this.formRef}
          style={!isMobile ? { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', display: loaded ? 'block' : 'none' } : { paddingBottom: '20px', display: loaded ? 'block' : 'none' }}
          onValuesChange={this.handleFormChange}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
            <Form.List
              name='budget_items'
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <Card
                    title={this.state.done && this.state.done.length > 0 && 'Budżet'}
                    className={isMobile ? 'on-mobile' : ''}
                  >
                    {fields.map(({ key, name, ...restField }) => (
                      <React.Fragment key={key}>

                        <Row gutter={[3, 0]}>
                          <Col xs={24} md={10} lg={10}>
                            <Form.Item
                              {...restField}
                              name={[name, 'nr']}
                              hidden
                              >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'id']}
                              hidden
                              >
                              <Input />
                            </Form.Item>
                            <FloatLabel label={this.state.budgetItemFocus === name && 'Nazwa'}>
                              <Form.Item
                                {...restField}
                                name={[name, 'name']}
                                validateTrigger={['onChange', 'onBlur']}
                                style={{ marginBottom: '10px' }}
                                rules={[
                                  { required: true, message: 'Podaj nazwę' },
                                  {
                                    validator: async (_, name) => {
                                      if (name) {
                                        const items = this.formRef.current?.getFieldValue('budget_items').filter(x => x.name === name)
                                        const itemsDone = this.state.done.filter(x => x.name === name)

                                        if (items.length > 1 || itemsDone.length > 1) {
                                          return Promise.reject(new Error('Elementy o tej samej nazwie już istnieje w tym budżecie'))
                                        } else {
                                          return Promise.resolve()
                                        }
                                      }
                                    }
                                  }
                                ]}
                              >
                                <Input
                                  placeholder={this.state.budgetItemFocus !== name ? 'Nazwa' : ''}
                                  className={this.state.budgetItemFocus === name ? 'field-float-input' : ''}
                                  onBlur={() => { this.handleBudgetItemFocus(null) }}
                                  onFocus={() => this.handleBudgetItemFocus(name)}
                                  autoComplete='off'
                                  style={ this.formRef.current?.getFieldValue('budget_items')[name].parent_id !== undefined ? { marginLeft: '20px' } : {}}
                                />
                              </Form.Item>
                            </FloatLabel>
                          </Col>

                          <Col xs={7} md={4} lg={4}>
                            <FloatLabel label={this.state.budgetItemFocus === name && 'Cena'}>
                              <Form.Item
                                {...restField}
                                name={[name, 'price']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={this.formRef.current?.getFieldValue('budget_items')[name].subitems?.length ? [] : [{ required: true, message: 'Podaj cenę' }]}
                                // hidden={this.formRef.current?.getFieldValue('budget_items')[name].subitems?.length}
                              >
                                <InputNumber
                                  className={this.state.budgetItemFocus === name ? 'field-float-number' : ''}
                                  placeholder={this.state.budgetItemFocus !== name ? 'Cena' : ''}
                                  min={0}
                                  style={{ width: '100%' }}
                                  onBlur={() => { this.handleBudgetItemFocus(null) }}
                                  onFocus={() => this.handleBudgetItemFocus(name)}
                                />
                              </Form.Item>
                            </FloatLabel>
                          </Col>

                          {this.formRef.current?.getFieldValue('budget_items')[name].subitems?.length > 0 &&
                          this.formRef.current?.getFieldValue('budget_items')[name].price > 0 &&
                            <Col xs={7} md={4} lg={4}>
                              {this.formRef.current?.getFieldValue('budget_items')[name].left_price < 0 &&
                                <span style={{ color: 'red' }}>Przekroczony o: {Math.abs(this.formRef.current?.getFieldValue('budget_items')[name].left_price)}</span>}
                              {this.formRef.current?.getFieldValue('budget_items')[name].left_price > 0 &&
                                'Pozostało: ' + this.formRef.current?.getFieldValue('budget_items')[name].left_price}
                            </Col>}

                          <Col xs={5} md={3} lg={3} className={!isMobile ? 'form-item-actions' : ''}>
                            <Popconfirm
                              title='Czy na pewno chcesz usunąć ten element?'
                              onConfirm={() => this.removeItem(remove, name)}
                              {...popconfirmItemDeleteProps}
                            >
                              <MinusCircleOutlined
                                className='dynamic-delete-button'
                                title='Usuń'
                              />
                            </Popconfirm>
                            {' '}
                            {this.formRef.current?.getFieldValue('budget_items')[name].price !== 0 &&
                            this.formRef.current?.getFieldValue('budget_items')[name].price !== undefined &&
                            this.formRef.current?.getFieldValue('budget_items')[name].name
                            && <CheckCircleOutlined
                              className='dynamic-delete-button'
                              title='Oznacz jako opłacone'
                              onClick={() => this.setMarkDoneModalData(name)}
                            />}
                            {' '}
                            {this.formRef.current?.getFieldValue('budget_items')[name].parent_id === undefined && <PlusCircleOutlined
                              className='dynamic-delete-button'
                              title='Dodaj sub element'
                              onClick={() => this.addSubitem(add, name, {key, restField})}
                            />}
                          </Col>
                        </Row>
                        {fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />}
                      </React.Fragment>
                    ))}

                    <Form.ErrorList errors={errors} />

                    <Button
                      type='secondary'
                      onClick={() => add({ id: this.getNextCounter() })}
                      icon={<PlusOutlined />}
                    >
                      Dodaj pozycję do budżetu
                    </Button>
                  </Card>
                </>
              )}
            </Form.List>

            {this.state.done && this.state.done.length > 0 &&
              <Card
                title='Pozycje kupione'
                className={isMobile ? 'on-mobile done-items' : 'done-items'}
              >
                {this.state.done.map((item, key) => (
                  <React.Fragment key={key}>
                    {key !== 0 && <Divider />}
                    <Row gutter={[3, 0]} key={item.id}>
                      <Col xs={24} md={10} lg={10} style={{ textAlign: 'left' }}>
                        {item.name}
                      </Col>
                      <Col xs={7} md={4} lg={4}>
                        {item.subitems === undefined
                          ?
                            <>
                              {item.price} {item.planned_price !== undefined && item.planned_price !== 0 && item.planned_price < item.price ? '(' + item.planned_price + ')' : ''}
                            </>
                          :
                            <>
                              {item.price} {item.planned_price !== undefined && item.planned_price !== 0 && item.planned_price < item.price ? '(' + item.planned_price + ')' : ''}
                            </>
                        }
                      </Col>
                      <Col xs={3} md={2} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                        {/*<EditOutlined
                          className='dynamic-delete-button'
                          title='Usuń'
                          disabled
                        />*/}
                      </Col>
                    </Row>
                    {item.subitems !== undefined
                      ?
                        item.subitems.map((subitem, idx) =>
                          <Row key={item.id + '_' + idx}>
                            <Col xs={24} md={10} lg={10}>
                              {' '}{subitem.name}
                            </Col>
                            <Col xs={7} md={4} lg={4}>
                              {subitem.price}
                            </Col>
                          </Row>)
                      : ''}
                    {/*fields.length - 1 !== key && isMobile && <Divider {...formItemsDivider} />*/}
                  </React.Fragment>
                ))}
              </Card>}
          </Space>

          <p>Suma zaplanowana do wydania: {this.state.sum} zł</p>
          <p>Do tej pory wydano: {this.state.doneSum} zł</p>
          {this.state.maxValue > 0 && (
            <p>Pozostało do zaplanowania: {this.state.maxValue - this.state.sum - this.state.doneSum} zł</p>
          )}
          {this.state.maxValue > 0 && (
            <p>Suma maks. do wydania: {(this.state.maxValue - this.state.doneSum)} zł</p>
          )}
        </Form>
      </>
    )
  }
}

export default withTranslation()(BudgetForm)
