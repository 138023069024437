/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input
} from 'antd'

import { withTranslation } from 'react-i18next'

const formItemLayout = {
  wrapperCol: {
    xs: 24
  }
}

class BudgetNameForm extends React.Component {
  state = {
    timer: null
  }

  formRef = React.createRef()

  handleFormChange = () => {
    this.saveFormData()
  }

  saveFormData = () => {
    if (this.state.timer) return

    const _this = this
    const timer = setTimeout(() => {
      _this.props.formAction(this.formRef.current?.getFieldsValue())
      _this.setState({ timer: null })
    }, 2000)

    this.setState({ timer })
  }

  componentDidMount () {
    this.formRef.current.setFieldsValue(this.props.formData)
  }

  render () {
    return (
      <>
        <Form
          layout='horizontal'
          ref={this.formRef}
          onValuesChange={this.handleFormChange}
        >
          <Form.Item
            name='name'
            rules={[{ required: true, message: 'Podaj nazwę budżetu' }]}
            {...formItemLayout}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default withTranslation()(BudgetNameForm)
