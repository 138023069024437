/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, InputNumber
} from 'antd'

import { withTranslation } from 'react-i18next'

const formItemLayout = {
  wrapperCol: {
    xs: 24
  }
}

class BudgetMarkDoneForm extends React.Component {
  formRef = React.createRef()

  handleFormChange = () => {
    this.props.setData(this.formRef.current?.getFieldValue('price'))
  }

  componentDidMount () {
    this.props.setFormRef(this.formRef)
    this.formRef.current.setFieldsValue(this.props.formData)
    this.props.setData(this.props.formData.price)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.formData !== this.props.formData) {
      this.formRef.current.setFieldsValue(this.props.formData)
      this.props.setData(this.props.formData.price)
    }
  }

  render () {
    return (
      <>
        <Form
          layout='horizontal'
          ref={this.formRef}
          onValuesChange={this.handleFormChange}
        >
          <Form.Item
            name='price'
            label={this.props.t('Faktycznie zapłacona kwota')}
            rules={[{ required: true, message: 'Podaj kwotę' }]}
            {...formItemLayout}
          >
            <InputNumber min={0} />
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default withTranslation()(BudgetMarkDoneForm)
