/* eslint no-unused-expressions: "off" */
import React from 'react'
import { connect } from 'react-redux'
import { setBudgetName, setBudgetHash, setBudgetMaxValue } from '../../store/actions'
// import ReactGA from 'react-ga4'
import BudgetForm from '../../styledComponents/main/budgetForm'
import { updateBudget, getBudget } from '../../utils/actions'

import { Redirect } from 'react-router'

class EditBudget extends React.Component {
  state = {
    formData: undefined,
    hash: undefined
  }

  formRef = React.createRef()
  mobileDropdownRef = React.createRef()

  redirect = (route) => {
    this.props.history.push(route)
  }

  setFormUnsaved = (formUnsaved) => {
    this.setState({ formUnsaved })
  }

  getBudget = (id) => {
    getBudget(id).then((json) => {
      if (this._isMounted) {
        this.setState({ formData: json })

        this.props.setBudgetName(json.name)
        this.props.setBudgetMaxValue(json.max_value)
        this.props.setBudgetHash(id)
      }
    })
  }

  updateBudget = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updateBudget(_this.state.hash, data).then((json) => {
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  componentDidMount () {
    const { hash } = this.props.match.params
    this._isMounted = true

    this.setState({ hash })
    this.getBudget(hash)
  }

  componentWillUnmount () {
    this._isMounted = false

    this.props.setBudgetName(undefined)
    this.props.setBudgetMaxValue(undefined)
    this.props.setBudgetHash(undefined)
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { isMobile } = this.props
    const { formData, makeRedirection } = this.state

    return (
      makeRedirection ? (
        <Redirect to={this.state.from === 'calendar' ? '/calendar/' + this.state.back_to : '/order'} />
      ) : (
        <>
          <BudgetForm
            formAction={this.updateBudget}
            formRef={this.formRef}
            isMobile={isMobile}
            formData={formData}
            makeRedirection={this.makeRedirection}
            screenSize={this.props.screenSize}
            budgetMaxValue={this.props.budgetMaxValue}
          />
        </>
      )
    )
  }
}

const mapStateToProps = state => ({
  budgetName: state.appRedux.budgetName,
  budgetMaxValue: state.appRedux.budgetMaxValue,
})

const mapDispatchToProps = {
  setBudgetName,
  setBudgetMaxValue,
  setBudgetHash
}

const EditBudgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBudget)

export default EditBudgetContainer
