/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'
import { connect } from 'react-redux'
import CreateBudgetButton from './createBudgetButton'

const { location = {} } = typeof window !== 'undefined' ? window : {}

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile: undefined,
      show: !location.port
    }
  }

  componentDidMount () {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }
  }

  render () {
    return (
      <div
        className='templates-wrapper'
        ref={(d) => {
          this.dom = d
        }}
      >
        <CreateBudgetButton />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const HomeContainer = connect(
  mapStateToProps
)(Home)

export default HomeContainer
