export const setAuth = authenticated => ({
  type: 'SET_AUTH',
  authenticated
})

export const setInstallPromptShowed = installPromptShowed => ({
  type: 'SET_INSTALL_PROMPT_SHOWED',
  installPromptShowed
})

export const setBudgetName = budgetName => ({
  type: 'SET_BUDGET_NAME',
  budgetName
})

export const setBudgetMaxValue = budgetMaxValue => ({
  type: 'SET_BUDGET_MAX_VALUE',
  budgetMaxValue
})

export const setBudgetHash = budgetHash => ({
  type: 'SET_BUDGET_HASH',
  budgetHash
})
