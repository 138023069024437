/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { Button } from 'antd'
import { createBudget } from '../../utils/actions'
import { Redirect } from 'react-router'
import { LoadingOutlined } from '@ant-design/icons'

class CreateBudgetButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      makeRedirection: false,
      loading: false
    }
  }

  createBudget = () => {
    this.setState({ loading: true })

    createBudget().then((json) => {
      this.setState({ hash: json?.hash, loading: false }, () => {
        if (this.props.newWindow) {
          window.open(window.location.origin + '/b/' + this.state.hash, '_blank', 'noopener,noreferrer')
        } else {
          this.makeRedirection()
        }
      })
      console.log('Budget created', json?.hash)
    })
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  render () {
    const { label } = this.props
    const { makeRedirection, loading } = this.state

    return (
      makeRedirection ? (
        <Redirect to={'/b/' + this.state.hash} />
      ) : (
        <Button onClick={this.createBudget} style={{ marginTop: '40px' }} disabled={loading}>{loading && <LoadingOutlined style={{ fontSize: 14 }} spin />}{label || 'Stwórz budżet'}</Button>
    ))
  }
}

export default CreateBudgetButton
