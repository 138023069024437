import { Menu } from 'antd'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import CreateBudgetButton from '../../components/main/createBudgetButton'

class StyledMenuMain extends React.Component {
  hideMobileMenu = () => {
    this.props.onHideMobileMenu()
  }

  render () {
    const { isMobile } = this.props

    return (
      this.props.isAuthenticated ? (
        <Menu mode={!isMobile ? 'horizontal' : 'inline'} onClick={isMobile && this.hideMobileMenu} disabledOverflow>
          <Menu.Item key='desktop'>
            <Link to='/'>Pulpit</Link>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu mode={!isMobile ? 'horizontal' : 'inline'} onClick={isMobile && this.hideMobileMenu} disabledOverflow>
          {!window.matchMedia('(display-mode: standalone)').matches ? (
            <>
              <Menu.Item key='index'>
                <Link to='/'>Strona główna</Link>
              </Menu.Item>
              <Menu.Item key='create-budget'>
                <CreateBudgetButton newWindow label='Stwórz nowy budżet' />
              </Menu.Item>
            </>
          ) : ''}
        </Menu>
      )
    )
  }
}

export default withRouter(StyledMenuMain)
