import { combineReducers } from 'redux'

const appRedux = (state = {}, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        authenticated: action.authenticated
      }
    case 'SET_INSTALL_PROMPT_SHOWED':
      return {
        ...state,
        installPromptShowed: action.installPromptShowed
      }
    case 'SET_BUDGET_NAME':
      return {
        ...state,
        budgetName: action.budgetName
      }
    case 'SET_BUDGET_MAX_VALUE':
      return {
        ...state,
        budgetMaxValue: action.budgetMaxValue
      }
    case 'SET_BUDGET_HASH':
      return {
        ...state,
        budgetHash: action.budgetHash
      }
    default:
      return state
  }
}

const reducers = combineReducers({
  appRedux
})

export default reducers
