import React from 'react'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { removeDeviceData, openNotification } from '../../utils/helpers'
import { setAuth, setInstallPromptShowed, setBudgetName } from '../../store/actions'
import { logoutUser, updateBudgetInfo } from '../../utils/actions'
import StyledHeader from '../../styledComponents/common/header'
import PwaPrompt from './pwaPrompt'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router'

class Header extends React.Component {
  state = {
    makeRedirection: false
  }

  makeRedirection = () => {
    this.setState({ makeRedirection: true })
  }

  updateBudgetInfo = (data) => {
    const _this = this
    return new Promise(function (resolve, reject) {
      updateBudgetInfo(_this.props.budgetHash, data).then((json) => {
        resolve(json)
      }).catch((error) => reject(error))
    })
  }

  componentDidMount () {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      window.addEventListener('offline', () => {
        notification.warning({
          message: 'Jesteś offline',
          duration: 0,
          description: 'Zmiany w aplikacji nie będą zapisywane z powodu braku połączenia z siecią',
          placement: 'topRight'
        })
      })

      window.addEventListener('online', () => {
        notification.info({
          message: 'Ponownie jesteś online',
          duration: 0,
          placement: 'topRight'
        })
      })
    }
  }

  logout = () => {
    const { setAuth } = this.props

    logoutUser().then(() => {
      removeDeviceData('auth')
      removeDeviceData('refresh_token')
      setAuth(false)
      openNotification(this.props.t('You has been logged out'), this.props.t('See you later!'))
      this.makeRedirection()
    })
  }

  render () {
    const { authenticated, installPromptShowed, isMobile } = this.props
    const { makeRedirection } = this.state

    return (
      <>
        {makeRedirection ? (
          <Redirect to='/' />
        ) : ''}
        <PwaPrompt isAuthenticated={authenticated} installPromptShowed={installPromptShowed} isMobile={isMobile} setInstallPromptShowed={this.props.setInstallPromptShowed} />
        <StyledHeader
          isAuthenticated={authenticated}
          logout={this.logout}
          formData={{ name: this.props.budgetName, max_value: this.props.budgetMaxValue }}
          updateBudgetInfo={this.updateBudgetInfo}
          budgetHash={this.props.budgetHash}
          budgetName={this.props.budgetName}
          setBudgetName={this.props.setBudgetName}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated,
  installPromptShowed: state.appRedux.installPromptShowed,
  budgetName: state.appRedux.budgetName,
  budgetMaxValue: state.appRedux.budgetMaxValue,
  budgetHash: state.appRedux.budgetHash
})

const mapDispatchToProps = {
  setAuth,
  setInstallPromptShowed,
  setBudgetName
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

export default withTranslation()(HeaderContainer)
