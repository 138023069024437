import axios from 'axios'
import { openNotification, getDeviceData, saveDeviceData, removeDeviceData } from './helpers'
import settings from '../config'
import { setAuth } from '../store/actions'
import store from '../store'

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500
}

// intercept request to add authorization token
axios.interceptors.request.use(config => {
  const token = getDeviceData('auth')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  config.headers.common['X-LOCALE'] = 'pl_PL'
  return config
}, error => {
  Promise.reject(error)
})

// intercept response to catch http:401 to call refresh token or to logout user when refresh token is dead
axios.interceptors.response.use((response) => {
  if (response) {
    const originalRequest = response.config

    if (response.status === 401 && new URL(originalRequest.url).pathname === '/api/token/refresh') {
      // logout user
      store.dispatch(setAuth(false))
      removeDeviceData('auth')
      removeDeviceData('refresh_token')
      throw new axios.Cancel('Session expired')
    }

    if (response.status === 401 && new URL(originalRequest.url).pathname !== '/api/login_check' && new URL(originalRequest.url).pathname !== '/api/api/login_check') {
      return axios.post(`${settings.api_url}/api/token/refresh`, { refresh_token: getDeviceData('refresh_token') })
        .then(res => {
          if (res.status === 200) {
            saveDeviceData('auth', res.data.token)
            saveDeviceData('refresh_token', res.data.refresh_token)
            return axios(originalRequest)
          }
        })
    }
  }

  return response
}, (error) => {
  return Promise.reject(error)
})

export function authenticateUser (username, password) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/login_check`,
      data: {
        username: username,
        password: password
      }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function registerUser (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/register`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function logoutUser () {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/logout`,
      data: {
        refresh_token: getDeviceData('refresh_token')
      }
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function createBudget (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/budget/create`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function getBudget (id) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/api/budget/${id}`
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateBudget (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/budget/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}

export function updateBudgetInfo (id, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/api/budget/info/${id}`,
      data: data
    }).then(function (response) {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        openNotification('Ups, wystąpił błąd!', (response.data && response.data.message))
        reject(response.data)
      }
    }).catch(function (error) {
      openNotification('Ups, wystąpił błąd!', error.message)
      reject(error)
    })
  })
}
