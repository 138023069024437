import React from 'react'
import { Modal } from 'antd'
import StyledOptionsForm from '../../styledComponents/main/budgetOptionsForm'
import { connect } from 'react-redux'
import { setBudgetMaxValue } from '../../store/actions'

class BudgetOptionsModal extends React.Component {
  handleCancel = () => {
    this.props.setData(null)
  }

  render () {
    const { data } = this.props

    return (
      <div>
        <Modal
          visible={!!data}
          title='Opcje budżetu'
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledOptionsForm
            data={data}
            formData={this.props.formData}
            formAction={this.props.formAction}
            setBudgetMaxValue={this.props.setBudgetMaxValue}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  setBudgetMaxValue
}

const BudgetOptionsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetOptionsModal)

export default BudgetOptionsModalContainer
