import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import ScrollToTop from './components/common/scrollToTop'
import Main from './components/main'
import EditBudget from './components/main/editBudget'

import { setAuth, setInstallPromptShowed } from './store/actions'
import { getCookie, getDeviceData } from './utils/helpers'
import withTracker from './utils/withTracker'

class Routes extends React.Component {
  componentDidMount () {
    const { setAuth, setInstallPromptShowed } = this.props
    const token = getDeviceData('auth')
    const installPromptShowed = getCookie('install_prompt_showed')

    if (token) {
      setAuth(true)
    }
    if (installPromptShowed) {
      setInstallPromptShowed(true)
    }
  }

  render () {
    // const { authenticated } = this.props

    // const CalcWithTracker = withTracker(CalcIndex)
    // const CalcProWithTracker = withTracker(CalcProIndex)
    // const PromoShowWithTracker = withTracker(PromoShow)
    // const UserProfileWithTracker = withTracker(UserProfile)
    // const RegisterWithTracker = withTracker(Register)
    // const AddOrderWithTracker = withTracker(AddOrder)
    // const EditOrderWithTracker = withTracker(EditOrder)
    // const PantryIndexWithTracker = withTracker(PantryIndex)
    // const AddPantryWithTracker = withTracker(AddPantry)
    // const EditPantryWithTracker = withTracker(EditPantry)
    // const RecipeIndexWithTracker = withTracker(RecipeIndex)
    // const AddRecipeWithTracker = withTracker(AddRecipe)
    // const EditRecipeWithTracker = withTracker(EditRecipe)
    // const CalendarShowWithTracker = withTracker(CalendarShow)
    // const OrderConfigWithTracker = withTracker(OrderConfig)
    // const TopperIndexWithTracker = withTracker(TopperIndex)
    // const TopperShowWithTracker = withTracker(TopperShow)
    const EditBudgetWithTracker = withTracker(EditBudget)


    // {authenticated
    //   ? (
    //     <Switch>
    //       <Route exact path='/' component={withTracker(PanelIndex)} />
    //       <Route exact path='/login' component={withTracker(UserLogin)} />
    //     </Switch>)
    //   : (
    //     <Switch>
    //       <Route exact path='/login' component={withTracker(UserLogin)} />
    //       {window.matchMedia('(display-mode: standalone)').matches ? (
    //         <Route exact path='/' component={withTracker(UserLogin)} />
    //       ) : (
    //         <Route component={withTracker(Main)} />
    //       )}
    //     </Switch>
    //   )}
    return (
      <>
        <ScrollToTop />
        <Switch>
          <Route exact path='/b/:hash' render={(props) => <EditBudgetWithTracker {...props} isMobile={this.props.isMobile} />} />
          <Route component={withTracker(Main)} />
        </Switch>
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const mapDispatchToProps = {
  setAuth,
  setInstallPromptShowed
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)

export default RoutesContainer
