import React from 'react'
import { Modal } from 'antd'
import StyledMarkDoneForm from '../../styledComponents/main/budgetMarkDoneForm'

class BudgetMarkDoneModal extends React.Component {
  state = {
    onOkAction: function () {},
    priceData: undefined,
    formRef: undefined
  }

  handleCancel = () => {
    this.props.setData(null)
  }

  setFormRef = (formRef) => {
    this.setState({ formRef })
  }

  setPriceData = (priceData) => {
    this.setState({ priceData })
  }

  onOk = () => {
    this.props.onOkAction(this.state.priceData)
    this.handleCancel()
  }

  render () {
    const { data } = this.props

    return (
      <div>
        <Modal
          visible={!!data}
          title='Oznacz pozycję jako opłaconą'
          onCancel={this.handleCancel}
          onOk={this.onOk}
        >
          <StyledMarkDoneForm
            setFormRef={this.setFormRef}
            setData={this.setPriceData}
            formData={data}
          />
        </Modal>
      </div>
    )
  }
}

export default BudgetMarkDoneModal
